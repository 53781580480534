import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import styled from "styled-components"
import { graphql } from "gatsby"
import { BlogLayout } from "../../components/BlogLayout"

const imagePrefix = "/projects/laundryview"

class LaundryViewProject extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="LaundryView" />
        <BlogLayout>
          <div className="blog-title">LaundryView</div>

          <div className="tag-container">
            <a
              href="https://play.google.com/store/apps/details?id=com.cptmango.sbu_laundryview"
              className="live"
            >
              Download on <strong>Google Play</strong>
            </a>
            <a
              href="https://github.com/sdesingh/sbu-laundryview-android"
              className="live"
            >
              ⚙️ src
            </a>
          </div>

          <div className="tech-stack">
            {technicalStack.map(t => (
              <span className="item">{t}</span>
            ))}
          </div>

          {/* <p className="subtitle">
            Allows Stony Brook University students to keep track of their
            laundry on campus. Over the years, the app has been used by 3000+
            students.
          </p> */}

          <hr />
          <p>
            This was my first <em>real</em> project. And I learned quite a lot
            during my freshman/sophomore year developing and deploying this app.
            The real challenge was creating a native app for two separate
            platforms which meant learning Swift for iOS and Java for Android. I
            definitely want to re-visit this and give the app a facelift along
            with adding some more functionality. Let's see when that happens! 😪
          </p>

          <h3>Screenshots</h3>
          <hr />
          <ImageContainer>
            <img src={`${imagePrefix}/screen_1.png`} />
            <img src={`${imagePrefix}/screen_2.png`} />
            <img src={`${imagePrefix}/screen_3.png`} />
          </ImageContainer>
        </BlogLayout>
      </Layout>
    )
  }
}

export default LaundryViewProject

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const technicalStack = [
  "Java",
  "Swift",
  "Node.js",
  "Express.js",
  "Typescript",
  "React Native",
]

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`
